body[data-page='chromeindex']{
  padding-bottom: env(safe-area-inset-bottom);
  &::before {
    background-image: url('../img/chrome/index/bg.jpg');
  }
}
[data-page-id='chromeindex']{
  .chrome-contents {
    position: relative;
    z-index: 7;
  }
  .ch-index {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    &-inner {
      position: relative;
      overflow: hidden;
      width: 100%;
      max-width: 1275px;
      margin: 30px auto 0;
    }
    &-title {
      display: flex;
      justify-content: center;
      transform: translateX(-2%);
      img {
        max-width: 1275px;
        height: auto;
      }
      @media (min-width: 760px) and (max-height: 1080px) {
        img {
          width: auto;
          height: min(calc(827px * 100 * 1vh / 1080px), 827px);
          // min-height: 640px;
        }
      }
    }
    .buttons {
      display: flex;
      justify-content: center;
      margin-top: 6px;
      .anchor {
        &:first-of-type {
          margin-right: 31px;
        }
      }
    }
    @media (min-width: 760px) and (max-height: 475px) {
      display: block;
      position: static;
      padding: min(vw(80), 30px) 0 min(vw(80), 80px);
      min-width: inherit;
    }

    @include l-mobile() {
      display: block;
      position: static;
      padding-top: vw(80);
      &-inner {
        margin-top: 0;
        padding: 0;
      }
      &-title {
        transform: none;
        img {
          height: auto;
          width: 100%;
        }
      }
      .buttons {
        width: vw(457);
        margin: vw(12) auto 0;
        .anchor {
          &:first-of-type {
            margin-right: 0;
            margin-bottom: vw(26);
          }
        }
      }
      @media (orientation: landscape) {
        &-inner {
          overflow: auto;
        }
      }
    }
  }
}