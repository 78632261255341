.p-lower {
  padding-top: 125px;
  @include l-more-than-mobile {
    width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  @include l-mobile {
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
  }
  &--aboutapp {
    @include l-more-than-mobile {
      padding-top: 44px;
    }
    @include l-mobile {
      padding-top: 27px;
    }
  }
}
