@charset "UTF-8";

@import url('https://fonts.googleapis.com/css?family=M+PLUS+1p:800&display=swap');

// ==========================================================================
// Foundation
// ==========================================================================
@import "foundation/_variables";
@import "foundation/_variables-easings";
@import "foundation/_keyframes";
@import "foundation/_functions";

// Mixins
@import "foundation/_mixin-utils";

// Base
@import "foundation/_normalize";
@import "foundation/_global";

// ==========================================================================
// Layout
// ==========================================================================
@import "layout/_contents";
@import "layout/_gf";
@import "layout/_gh";
@import "layout/_page";

// ==========================================================================
// Object
// ==========================================================================
// -----------------------------------------------------------------
// Component
// -----------------------------------------------------------------
@import "object/component/_fade-in-up";
@import "object/component/_fade-in";
@import "object/component/_img-wide";
@import "object/component/_split-str";
@import "object/component/_link-alpha";
@import "object/component/_link-text";

// -----------------------------------------------------------------
// Project
// -----------------------------------------------------------------
@import "object/project/common/_global-menu";
@import "object/project/common/_ft-app-link";
@import "object/project/common/_lower";
@import "object/project/common/_lower-heading";
@import "object/project/common/_template";
@import "object/project/index/_kv";
@import "object/project/index/_news";
@import "object/project/index/_pc-link";
@import "object/project/manual/_slideshow";
@import "object/project/faq/_list";
@import "object/project/faq/_contact";
@import "object/project/aboutapp/_content";
@import "object/project/aboutapp/_kv";
@import "object/project/news/_detail";

// -----------------------------------------------------------------
// Utility
// -----------------------------------------------------------------
@import "object/utility/_animdel";
@import "object/utility/_cur";
@import "object/utility/_d";
@import "object/utility/_dn";
@import "object/utility/_ff";
@import "object/utility/_fs";
@import "object/utility/_fw";
@import "object/utility/_fz";
@import "object/utility/_lh";
@import "object/utility/_m";
@import "object/utility/_no-selection";
@import "object/utility/_p";
@import "object/utility/_ta";
@import "object/utility/_trsde";
@import "object/utility/_tt";
@import "object/utility/_whs";

// -----------------------------------------------------------------
// Vendor
// -----------------------------------------------------------------
// @import "object/vendor/_xxx";

// -----------------------------------------------------------------
// Chrome版
// -----------------------------------------------------------------
@import "chrome/common/_extend";
@import "chrome/common/_variable";
@import "chrome/common/_style";
@import "chrome/page/_index";
@import "chrome/page/_install";
@import "chrome/page/_about";
@import "chrome/page/_faq";