.l-gh {
  color: $color-text;
  &__bg {
    height: 80px;
    position: fixed;
    top: 0; left: 0; right: 0;
    z-index: 1000;
    background-color: $color-bg;
    pointer-events: none;
    @include l-more-than-mobile {
      // bottom: 0;
      // background-image: url(../img/mobile-app/common/shadow.png);
      // background-position: top;
      // background-size: 100%;
      // background-repeat: no-repeat;
    }
    // &--sp-blue {
    //   @include l-mobile {
    //     height: 80px;
    //     background-color: $color-bg;
    //   }
    // }
    // &--sp-shadow {
    //   @include l-mobile {
    //     padding-top: (319 / 750) * 100%;
    //     background-image: url(../img/mobile-app/common/shadow_sp.png);
    //     background-position: top;
    //     background-size: 100%;
    //     background-repeat: no-repeat;
    //   }
    // }
  }
  &__wrap {
    position: fixed;
    top: 0; left: 0; right: 0;
    z-index: 10000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;

  }
  &__menu {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    @include l-more-than-mobile {
      margin-right: 80px;
    }
    &-circ {
      &-in {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: #fff;
      }
    }
    &-dots {
      width: 13px;
      height: 11px;
      margin-top: -2px;
      position: relative;
    }
    &-dot {
      width: 4px;
      height: 4px;
      position: absolute;
      border-radius: 50%;
      background-color: #0d3156;
      &--01 {
        top: 0; left: 4px;
      }
      &--02 {
        top: 7px; left: 9px;
      }
      &--03 {
        top: 7px; left: 0;
      }
    }
    &-line {
      width: 2px;
      height: 2px;
      position: absolute;
      top: 5px; left: 5px;
      background-color: #0e3156;
      transform-origin: center;
      &--01 {
        transform: rotate(45deg) scaleY(1);

      }
      &--02 {
        transform: rotate(-45deg) scaleY(1);
      }
    }
    &-text {
      position: absolute;
      top: 11px; left: 51px;
      @include fontSize(14);
      text-transform: uppercase;
    }
  }
  &__logo {
    width: 200px;
    img {
      display: block;
      width: 100%;
    }
  }
  &__dl {
    width: 140px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
    box-sizing: border-box;
    background-color: #000;
    color: #fff;
    text-decoration: none;
    &-icon {
      width: 14px;
      img {
        width: 100%;
        display: block;
      }
    }
    &-text {
      @include fontSize(14);
    }
  }
  //
  //interaction
  //
  &__bg {
    opacity: 0;
    transition: opacity .3s ease;
    &.is-scroll {
      opacity: 1;
    }
  }
  &__menu {
    &-dot {
      transition-duration: .5s;
      transition-timing-function: ease;
      transition-property: transform, opacity;
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    &-line {
      transition-duration: .5s;
      transition-timing-function: ease;
      transition-property: transform, opacity;
      opacity: 0;
    }
    &.is-opened &{
      &-dot {
        opacity: 0;
        &--01 {
          transform: translate3d(0, 4px, 0);
        }
        &--02 {
          transform: translate3d(-5px, -3px, 0);
        }
        &--03{
          transform: translate3d(4px, -3px, 0);
        }
      }
      &-line {
        opacity: 1;
        &--01 {
          transform: rotate(45deg) scaleY(8.5);
        }
        &--02 {
          transform: rotate(-45deg) scaleY(8.5);
        }
      }

    }
    &-text {
      transition: opacity .5s ease;
      opacity: 0;
      &.is-shown {
        opacity: 1;
      }
    }

    &.is-over &{
      &-circ {
        &-in {
          animation-name: bounce;
          animation-duration: .3s;
          animation-timing-function: ease;
          animation-fill-mode: forwards;
        }
      }
    }
  }
}
