body[data-content='chrome'] {
  [data-page-id='chromeinstall'],
  [data-page-id='chrometerms'] {
    .contentsbox {
      .ch-jump {
        margin-top: 18px;
      }
      &-list {
        &-item {
          list-style-type: none;
          .contentsbox-text {
            margin-top: 10px;
          }
          &:nth-of-type(n + 2) {
            margin-top: 36px;
          }
        }
      }
      &-lead {
        @extend %notosans-medium;
        color: color(text);
        font-size: 16px;
        letter-spacing: 0.04em;
      }
      &-text {
        &-indent {
          padding-left: 1em;
        }
        &-link {
          color: color(text);
          text-decoration: none;
          &-center {
            text-align: center;
          }
        }
      }
      &-textarea {
        margin-top: 30px;
        .contentsbox-text {
          margin-top: 20px;
        }
      }
      &-form {
        margin-top: 10px;
        color: color(text);
        font-size: 14px;
        &-list {
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding: 10px 0;
          border-bottom: 1px solid rgba(color(text), .2);
          &:last-of-type {
            border-bottom: 0;
          }
        }
        &-textarea {
          display: flex;
        }
        &-link {
          display: inline-block;
          margin-left: 20px;
          color: color(text);
          text-decoration: none;
        }
        &-check {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &-box {
            display: none;
            visibility: hidden;
            margin: 0;
            &:checked + .contentsbox-form-check-icon {
              background-image: url('../img/chrome/common/btn_check_on.png');
              background-size: contain;
            }
          }
          &-text {
            @extend %notosans-regular;
          }
          &-icon {
            display: inline-block;
            margin-right: 16px;
            width: 30px;
            height: 30px;
            background-image: url('../img/chrome/common/btn_check.png');
            background-size: contain;
          }
          &-button {
            display: inline-block;
            width: 296px;
            height: 47px;
            background-image: url('../img/chrome/install/btn_on.png');
            background-color: transparent;
            &-area {
              margin-top: 30px;
              text-align: center;
            }
            &.is-disabled {
              pointer-events: none;
              background-image: url('../img/chrome/install/btn_off.png');
            }
          }
        }
      }
      &:nth-of-type(3) {
        .contentsbox-inner {
          .contentsbox-list {
            margin-top: 36px;
          }
        }
      }
      &:nth-of-type(4) {
        .contentsbox-list-item {
          &:nth-of-type(4) {
            .ch-title-sub {
              margin-top: 46px;
            }
          }
        }
      }
    }
  }
  [data-page-id='chromeinstall'],
  [data-page-id='chrometerms'],
  [data-page-id='chromeopensource'],
  [data-page-id='chromesensitivesite'] {
    .contentsbox {
      &-table {
        margin-top: 46px;
        .content-table {
          margin-top: 16px;
          &-head {
            background-color: rgba(color(text), .1);
            .content-table-th,
            .content-table-td {
              @extend %notosans-medium;
            }
          }
          &-th,
          &-td {
            padding: 16px 26px;
            line-height: 1.5;
          }
          &-th {
            width: 270px;
            vertical-align: baseline;
          }
        }
        &-note {
          @extend %notosans-medium;
          margin-top: 4px;
          padding-left: 1.5em;
          color: color(text);
          font-size: 14px;
        }
        &:nth-of-type(n + 2) {
          margin-top: 30px;
        }
      }
    }
  }
  [data-page-id='chromeopensource'],
  [data-page-id='chromesensitivesite'] {
    .contentsbox {
      &-table {
        margin-top: 0;
      }
    }
  }
  [data-page-id='chromedownload'] {
    .contentsbox {
      &:first-of-type {
        text-align: center;
        .contentsbox-text {
          margin-top: 14px;
        }
      }
    }
  }
  [data-page-id='chromemanual'] {
    .contentsbox-text-link {
      display: inline-block;
      padding-left: 1.5em;
      color: white;
      text-decoration: underline;
      transition: color .25s ease-out;
      &:hover {
        color: #00fbff;
      }
    }
    .contentsbox-text-indent {
      padding-left: 1.5em;
      text-indent: -1.5em;
      &-long {
        padding-left: 9.3em;
        text-indent: -9.3em;
      }
      &:nth-of-type(n + 2) {
        margin-top: 0;
      }
    }
  }
}