$count: 40;

.u-m {
  &-t {
    @for $i from 0 through $count {
      &-#{$i * 5} {
        @include l-more-than-mobile {
          margin-top: floor(($i * 5) * 1px);
        }
        @include l-mobile {
          margin-top: floor(($i * 3.33) * 1px);
        }
      }
    }
    &-a {
      margin-top: auto;
    }
  }
  &-b {
    @for $i from 0 through $count {
      &-#{$i * 5} {
        @include l-more-than-mobile {
          margin-bottom: floor(($i * 5) * 1px);
        }
        @include l-mobile {
          margin-bottom: floor(($i * 3.33) * 1px);
        }
      }
    }
    &-a {
      margin-bottom: auto;
    }
  }
  &-r {
    @for $i from 0 through $count {
      &-#{$i * 5} {
        @include l-more-than-mobile {
          margin-right: floor(($i * 5) * 1px);
        }
        @include l-mobile {
          margin-right: floor(($i * 3.33) * 1px);
        }
      }
    }
    &-a {
      margin-right: auto;
    }
  }
  &-l {
    @for $i from 0 through $count {
      &-#{$i * 5} {
        @include l-more-than-mobile {
          margin-left: floor(($i * 5) * 1px);
        }
        @include l-mobile {
          margin-left: floor(($i * 3.33) * 1px);
        }
      }
    }
    &-a {
      margin-left: auto;
    }
  }
}
