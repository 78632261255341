.p-ft-app-link {
  background-color: $color-app-link-bg;
  padding-top: 50px;
  padding-bottom: 50px;
  &__wrap {
    display: flex;
    align-items: center;
    @include l-more-than-mobile {
      width: 800px;
      justify-content: space-between;
      margin-left: auto;
      margin-right: auto;
    }
    @include l-mobile {
      flex-direction: column;
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  &__app-icon {
    display: flex;
    align-items: center;
    @include l-mobile {
      flex-direction: column;
    }
    &-thum {
      width: 73px;
      @include l-more-than-mobile {
        margin-right: 22px;
      }
      @include l-mobile {
        margin-bottom: 14px;
      }
      img {
        width: 100%;
        display: block;
      }
    }
    &-text {
      font-family: $font-m-plus;
      @include fontSize(20);
      line-height: calc( 52 / 40 );
      letter-spacing: -0.05em;
    }
  }
  &__google-play {
    width: 160px;
    @include l-mobile {
      margin-top: 15px;
    }
    img {
      width: 100%;
      display: block;
    }
  }
}
