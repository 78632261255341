.l-gf {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 55px;
  padding-bottom: 45px;
  background-color: $color-bg-dark;
  color: $color-text;
  @include l-mobile {
    padding-left: 20px;
    padding-right: 20px;
  }
  &__wrap {

  }
  &__logos {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 44px;
    &-item {
      img {
        display: block;
        width: 100%;
      }
      &--koukaku {
        width: calc(251 / 2) * 1px;
        height: auto;
      }
      &--warpdrive {
        width: calc(302 / 2) * 1px;
        margin-left: 20px;
      }
    }
  }
  &__navi {
    display: flex;
    justify-content: center;
    @include fontSize(12);
    @include l-mobile {
      flex-wrap: wrap;
      margin-bottom: 3px;
    }
    &-wrap {
      // width: 100%;
      display: flex;
      justify-content: center;
      @include l-mobile {
        width: 100%;
      }
    }
    &-item {
      @include l-more-than-mobile {
        margin-bottom: 12px;
        margin-left: 10px;
        margin-right: 10px;
      }
      @include l-mobile {
        margin-bottom: 8px;
        margin-left: calc( 20 / 670 ) * 100%;
        margin-right: calc( 20 / 670 ) * 100%;
      }
    }
  }
  &__copyright {
    @include fontSize(10);
    text-align: center;
    color: rgba(255, 255, 255, .5);
  }
}
