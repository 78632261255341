@use "sass:math";

body[data-content='chrome'] {
  min-height: initial;
  background-color: color(main);
  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-image: url('../img/chrome/common/bg.jpg');
    background-size: cover;
    background-position: center top;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  img {
    width: 100%;
    height: auto;
  }

  .wrap {
    width: 70vw;
    max-width: 1300px;
    margin: 0 auto;
    @media (max-width: 1300px) {
      @include l-mobile() {
        margin: 0 vw(45);
      }
    }
  }

  .chrome-all {
    line-height: 1.5;
    min-width: 1024px;
    padding-top: 150px;
    padding-bottom: 200px;
    position: relative;
    opacity: 0;
    transition: opacity 1.5s ease-out;
    z-index: 5;
    overflow: hidden;
    &[data-page-id="chromeindex"] {
      padding-top: 0;
      padding-bottom: 0;
      .ch-footer {
        position: fixed;
        z-index: 5;
        margin-top: 0;
        transform: none;
        background: none;
        &-return {
          display: none;
        }
      }
    }
    &[data-page-id="chromefaq"] {
      .ch-header {
        transform: none;
        &::after {
          display: none;
        }
      }
      .ch-footer {
        transform: none;
        &-return {
          display: none;
        }
      }
    }
    &[data-status="active"] {
      opacity: 1;
      visibility: visible;
    }
  }
  .anchor {
    display: inline-block;
    position: relative;
    &-image {
      transition: opacity 0.25s ease-out;
      &:last-of-type {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        opacity: 0;
      }
    }
    &:hover {
      .anchor-image {
        // opacity: 0;
        &:last-of-type {
          opacity: 1;
        }
      }
    }
  }

  .ch-header {
    position: fixed;
    top: 0;
    right: 12px;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 10px 0 0 25px;
    &::after {
      content: '';
      width: 100%;
      height: 94px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      display: block;
      background-image: url('../img/chrome/common/bg_hearder.png');
      background-repeat: repeat-x;
      transition: transform 0.35s ease-out;
      transform: translateY(-102%);
    }
    &-top {
      justify-content: flex-end;
      transform: none;
      &::after {
        display: none;
      }
    }
    &.is-active {
      &::after {
        transform: translateY(0);
      }
    }
    &-install {
      margin: 4px -8px 0 34px;
      transition: transform 0.25s ease-out;
      &:hover {
        transform: scale(1.07);
      }
    }
    &-logo {
      position: relative;
      z-index: 5;
    }
  }

  .ch-navi {
    display: flex;
    padding-top: 6px;
    &-area {
      display: flex;
      background-image: url('../img/chrome/common/design.png');
      background-position: right 126px top 28px;
      background-repeat: no-repeat;
      position: relative;
      z-index: 5;
    }
    &-item {
      list-style: none;
      &:nth-of-type(2) {
        margin-left: 33px;
      }
      &:nth-of-type(3) {
        margin-left: 34px;
      }
      &:nth-of-type(4) {
        margin-left: 35px;
      }
      &[data-status='active'] {
        img {
          &:last-of-type {
            opacity: 1;
          }
        }
        .ch-navi-item-link {
          color: #00fbff;
          text-shadow: 0 0 7px #00fbff;
        }
      }
      &-link {
        @extend %seira-medium;
        transition: color .25s ease-out;
        color: #fff;
        text-decoration: none;
        font-size: 19px;
        &:hover {
          color: #00fbff;
          text-shadow: 0 0 7px #00fbff;
        }
      }
    }
  }

  .contentsbox {
    padding: 5px 20px 20px;
    border: 1px solid color(border);
    border-top: none;
    background-color: rgba(#311ba0, 0.4);
    position: relative;
    &::after {
      content: '';
      display: block;
      width: 7px;
      height: 62px;
      background-image: url('../img/chrome/common/box_design.png');
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      bottom: -1px;
      right: -8px;
    }
    & + .contentsbox {
      margin-top: 92px;
    }
    &-border-top {
      display: block;
      position: absolute;
      top: 39px;
      left: calc(100% + 40px);
      width: 100%;
      height: 1px;
      background-color: color(border);
    }
    &-corner {
      &::before, &::after {
        position: absolute;
        display: block;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
      }
      &::before {
        top: 1px;
        right: 0;
        border-width: 0 6px 6px 0;
        border-color: transparent #ffffff transparent transparent;
      }
      &::after {
        bottom: 0;
        left: 0;
        border-width: 6px 0 0 6px;
        border-color: transparent transparent transparent #ffffff;
      }
    }
    &-title {
      display: inline-block;
      position: absolute;
      bottom: 100%;
      left: -1px;
      padding: 10px 5px 0 20px;
      color: color(text);
      font-size: 18px;
      font-weight: bold;
      background-color: rgba(#311ba0, 0.4);
      border-top: 1px solid color(border);
      border-left: 1px solid color(border);
      height: 40px;
      &::before {
        content: '';
        position: absolute;
        top: -1px;
        left: 100%;
        z-index: 1;
        border-right: 1px solid color(border);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 40px 0 0 40px;
        border-color: transparent transparent transparent rgba(#311ba0, 0.4);
      }
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: -1px;
        left: 100%;
        z-index: 1;
        width: calc(40px * math.sqrt(2));
        height: 1px;
        background-color: color(border);
        transform: rotate(45deg);
        transform-origin: left top;
      }
      &-text {
        @extend %notosans-regular;
        font-size: 16px;
        line-height: 1.35;
        display: block;
        padding: 4px 14px;
        background-color: rgba(#695ed8, 0.5);
        transform: translateY(5px);
      }
    }
    &-anchor {
      display: inline-block;
      color: color(text);
      text-decoration: underline;
      transition: color 0.2s ease-out;
      &:hover {
        color: color(hover);
      }
    }
    &-text {
      @extend %notosans-light;
      font-size: 14px;
      line-height: 1.75;
      letter-spacing: 0.02em;
      color: color(text);
      margin-top: 35px;
      &:first-of-type {
        margin-top: 0;
      }
      &.font-light {
        font-weight: 300;
      }
      &.intro {
        padding-top: 35px;
        white-space: pre-wrap;
      }
    }
    &-inner {
      $corner: calc(100% - 15px);
      clip-path: polygon(0 0, 100% 0, 100% $corner, $corner 100%, 0 100%);
      background-color: rgba(#1b0d5b, 0.6);
      border: 1px solid #34218b;
      padding: 30px 40px;
      margin-top: 18px;
      position: relative;
      &::after {
        content: '';
        width: 50px;
        height: 3px;
        background-color: #34218b;
        position: absolute;
        bottom: 0px;
        right: 0px;
        transform: rotate(-45deg) translate(50%, 10px);
      }
      &:first-of-type {
        margin-top: 0;
      }
    }
    &-image {
      margin-top: 27px;
      & + .contentsbox-text {
        margin-top: 30px;
      }
    }
  }

  .content-table {
    width: 100%;
    margin-top: 50px;
    border-collapse: collapse;
    border: 1px solid #ffffff;
    &-th, &-td {
      font-size: 14px;
      font-weight: 300;
      line-height: 1.75;
      letter-spacing: 0.02em;
      color: color(text);
      text-align: left;
      border: 1px solid #ffffff;
      padding: 16px 25px;
    }
  }

  .ch-jump {
    &-item {
      margin-top: 20px;
      list-style: none;
      &:first-of-type {
        margin-top: 0;
      }
      &-anchor {
        @extend %notosans-medium;
        font-size: 16px;
        color: color(text);
        transition: filter 0.2s ease-out, color 0.2s ease-out;
        display: inline-flex;
        align-items: center;
        text-decoration: none;
        &::before {
          content: '';
          width: 24px;
          height: 24px;
          background-image: url('../img/chrome/common/icon_jump.png');
          background-size: contain;
          background-repeat: no-repeat;
          display: inline-block;
          margin-right: 17px;
        }
        &:hover {
          filter: drop-shadow(0 0 8px rgba(color(hover), 0.8));
          color: color(hover);
          &::before {
            background-image: url('../img/chrome/common/icon_jump_on.png');
          }
        }
      }
    }
  }

  .ch-title-sub {
    @extend %notosans-medium;
    font-size: 16px;
    letter-spacing: 0.06em;
    color: color(text);
    text-align: left;
    padding-left: 18px;
    border-left: 6px solid color(hover);
    & + .contentsbox-text {
      margin-top: 20px;
    }
  }

  .ch-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    &-logo {
      width: 95px;
      margin-right: 15px;
    }
    &-copyright {
      @extend %notosans-regular;
      font-size: 8px;
      color: #ffffff;
    }
    &-return {
      position: absolute;
      top: -20px;
      right: 100px;
      z-index: 5;
      transition: opacity 0.35s ease-out;
    }
    &-inner {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      padding: 35px 36px 20px;
      position: relative;
      z-index: 5;
      width: 100%;
    }
  }

  .only-sp {
    display: none !important;
  }

  @media (min-width: 760px) and (max-height: 450px) {
    .chrome-all {
      &[data-page-id="chromeindex"] {
        min-width: inherit;
        .ch-header {
          display: none;
        }
        .ch-footer {
          left: 0;
          bottom: vw(14);
        }
      }
    }
  }
  @include l-mobile() {
    &::before {
      background-image: url('../img/chrome/common/bg_sp.jpg');
    }
    .only-pc {
      display: none !important;
    }
    .only-sp {
      display: block !important;
    }
    .chrome-all {
      min-width: initial;
      padding-bottom: 0;
      &[data-page-id="chromeindex"] {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100dvh;
        padding-bottom: vw(80);
        .ch-index {
          padding-top: 0;
        }
        .ch-header {
          display: none;
        }
        .ch-footer {
          left: 0;
          bottom: vw(14);
        }
        @media (orientation: landscape) {
          height: auto;
          justify-content: flex-start;
          padding-top: vw(30);
          .ch-footer {
            &-logo {
              max-width: 120px;
            }
          }
        }
      }
    }
    .ch-footer {
      position: static;
      &-copyright {
        font-size: vw(16);
        margin-top: vw(5);
      }
      &-logo {
        width: vw(187);
      }
      &-inner {
        padding: 0 vw(20);
      }
    }
  }
}