.u-ta {
  &-l {
    text-align: left;
  }
  &-c {
    text-align: center;
  }
  &-r {
    text-align: right;
  }
  &-j {
    text-align: justify;
  }
}
