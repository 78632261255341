$color-facebook: #305097;
$color-twitter: #00aced;

$color-text: #fff;
$color-text-grey: #e6ecf2;

$color-key: #c22;
$color-strong: #c22;
$color-link: #c22;

$color-bg: #343AC6;
$color-bg-dark: #1E0083;
$color-light-blue: #72ffd9;
$color-app-img-bg: #5f45d1;
$color-app-link-bg: #262cbc;

$color-button: #A566CE;

// @font-face {
//   font-family: 'xxx';
//   font-weight: 400;
//   src:
//     url('../font/xxx.ttf') format('truetype'),
//     url('../font/xxx.woff') format('woff');
// }

$font-ja: "Hiragino Sans", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック体", "Yu Gothic", YuGothic, "メイリオ", Meiryo, sans-serif;
$font-ja-mincho: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "メイリオ", Meiryo, serif;
$font-m-plus: 'M PLUS 1p', sans-serif;

$break-point-pc-s: 1366px;
$break-point-tablet-l: 1024px;
$break-point-tablet-p: 768px;
$break-point-mobile-p: 414px;

$z-map: (
  contents: true,
);
