.u-trsde {
  @for $i from 1 through 20 {
    &-#{$i * 5} {
      transition-delay: $i * 0.05s;
    }
    @include l-more-than-mobile {
      &-pc-#{$i * 5} {
        transition-delay: $i * 0.05s;
      }
    }
    @include l-mobile {
      &-sp-#{$i * 5} {
        transition-delay: $i * 0.05s;
      }
    }
  }
}
