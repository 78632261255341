.p-faq-contact {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 25px;
  background-color: $color-button;
  color: $color-text;
  text-decoration: none;
  @include l-more-than-mobile {
    width: 240px;
  }
  &__label {
    font-family: $font-m-plus;
    @include fontSize(16);
  }
  //
  //interaction
  //
  transition-property: opacity;
  transition-duration: .3s;
  transition-timing-function: ease;
  &.is-over {
    opacity: .5;
  }
}
