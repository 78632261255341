.u-fs {
  &-n {
    font-style: normal;
  }
  &-i {
    font-style: italic;
  }
  &-o {
    font-style: oblique;
  }
}
