.p-manual-slideshow {
  @include l-more-than-mobile {
    margin-top: 60px;
    margin-bottom: 65px;
  }
  @include l-mobile {
    margin-top: 30px;
    margin-bottom: 85px;
  }
  &__tabs {
    &-inner {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 15px 12px;
    }
    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      font-size: 15px;
      font-weight: 800;
      text-align: center;
      width: 190px;
      height: 40px;
      background-color: rgba(#ffffff, 0.1);
      transition: background-color 0.15s ease;
      &.is-current {
        background-color: #a45ece !important;
      }
      @include l-more-than-mobile {
        &-space {
          display: none;
        }
        &:hover {
          background-color: rgba(#a45ece, 0.5);
        }
      }
    }
    @include l-mobile {
      overflow: auto;
      overflow-y: hidden;
      margin-left: -20px;
      margin-right: -20px;
      &-inner {
        gap: 0 5px;
        display: flex;
        align-items: center;
        padding-left: 20px;
      }
      &-item {
        flex-shrink: 0;
        width: auto;
        padding: 0 10px;
        min-width: 142px;
        &-space {
          width: 0px;
          height: 1px;
          padding: 0 1px;
          visibility: hidden;
          pointer-events: none;
          margin-left: 13px;
        }
      }
    }
  }
  &__image {
    position: relative;
    margin-top: 48px;
    @include l-more-than-mobile {
      background-repeat: no-repeat;
      background-position: center;
      background-size: 500px 500px;
    }
    @include l-more-than-mobile {
      margin-top: 45px;
    }
    &-bg-sp {
      @include l-mobile {
        width: calc((750 / 670) * 100% + 20px);
        position: absolute;
        top: 50%; left: 50%;
        padding-top: calc((750 / 670) * 100% + 20px);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        transform: translate3d(-50%, -50%, 0);
      }
    }
    &-wrap {
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;
      box-shadow: 0 5px 9.75px 0.25px rgba(#00105f, 0.3), 0 0 30px 0 rgba(#00105f, 0.8);
      border: 1px solid rgba(#72ffd9, .3);
      @include l-more-than-mobile {
        width: 240px;
        height: 428px;
      }
      @include l-mobile {
        width: calc(480 / 670) * 100%;
      }
    }
    &-in {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      position: relative;
      cursor: grab;
      @include l-mobile {
        padding-top: calc(854 / 480) * 100%;
      }
    }
    &-item {
      display: block;
      position: absolute;
      top: 0; left: 0;
      z-index: 5;
      opacity: 0;
      @include l-more-than-mobile {
        width: 240px;
      }
      @include l-mobile {
        width: 100%;
      }
      &.is-transition {
        transition: opacity 1s;
        transform: translate3d(0, 0, 0,);
      }
      &.is-current {
        z-index: 15;
        opacity: 1;
      }
    }
  }
  &__pager {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    &-item {
      margin-left: 10px;
      cursor: pointer;
      &:first-child {
        margin-left: 0;
      }
      &-in {
        border-radius: 50%;
        background-color: #fff;
        opacity: 0.2;
        @include l-more-than-mobile {
          width: 10px;
          height: 10px;
        }
        @include l-mobile {
          width: 8px;
          height: 8px;
        }
      }
    }
  }
  &__control {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 50%; left: 0; right: 0;
    transform: translate3d(0, -50%, 0);
    pointer-events: none;
    &-btn {
      width: 40px;
      height: 40px;
      position: relative;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.1);
      cursor: pointer;
      pointer-events: auto;
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0; left: 0; right: 0; bottom:0;
        background-image: url(../img/mobile-app/manual/arrow.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 10px 15px;
      }
      &--prev {
       &::after {
         transform: rotate(180deg);
       }
      }
      &--next {

      }
    }
  }
  &__text {
    margin-top: 39px;
    @include l-mobile {
      margin-top: 29px;
    }
    &-head {
      margin-top: 0;
      font-family: $font-m-plus;
      @include fontSize(20);
      @include l-more-than-mobile {
        margin-bottom: 2px;
      }
      @include l-mobile {
        margin-bottom: 3px;
      }
    }
    &-body {
      color: $color-text-grey;
      white-space: pre-wrap;
      line-height: calc(51 / 32);
    }
  }
  //
  //interaction
  //
  &__pager {
    &-item {
      &-in {
        transition: opacity .3s ease;
      }
      &.is-current & {
        &-in {
          opacity: 1;
        }
      }
      &.is-over & {
        &-in {
          opacity: 1;
          animation-name: bounce;
          animation-duration: .3s;
          animation-fill-mode: forwards;
          animation-timing-function: ease;
        }
      }
    }
  }
  &__control {
    &-btn {
      transition: transform .15s ease-in;
      &.is-over {
        transform: scale(1.2);
      }
    }
  }

}
