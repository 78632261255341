[data-page-id='chromeabout']{
  .ch-about {
    &-btn {
      margin-top: 50px;
      text-align: center;
      img {
        max-width: 297px;
      }
    }
  }
}