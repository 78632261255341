.p-aboutapp-kv {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @include l-more-than-mobile {
    background-image: url(../img/mobile-app/aboutapp/kv_bg.jpg);
  }
  @include l-mobile {

    background-image: url(../img/mobile-app/aboutapp/kv_bg_sp.jpg);
  }
  &__heading {
    font-family: $font-m-plus;
  }
}
