.p-news-detail {
  padding-bottom: 30px;
  &__head {
    margin-bottom: 30px;
    padding-bottom: 7px;
    border-bottom: 4px solid #fff;
    font-family: $font-m-plus;
  }
  &__heading {
    margin-top: 0;
    margin-bottom: 0;
    @include fontSize(24);
    line-height: calc(52 / 48);
  }
  &__date {
    @include fontSize(14);
  }
  &__content {
    &-text {
      white-space: pre-wrap;
    }
  }
}
