.p-index-pc-link {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @include l-more-than-mobile {
    padding-top: 90px;
    padding-bottom: 100px;
    background-image: url(../img/mobile-app/index/pc_link_bg.jpg);
  }
  @include l-mobile {
    padding-top: 45px;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
    background-image: url(../img/mobile-app/index/pc_link_bg_sp.jpg);
  }
  &__wrap {
    @include l-more-than-mobile {
      width: 960px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &__heading {
    margin-top: 0;
    text-align: center;
    font-family: $font-m-plus;
    @include fontSize(20);
    @include l-more-than-mobile {
      margin-bottom: 17px;
    }
    @include l-mobile {
      margin-bottom: 27px;
      line-height: calc(52 / 40);
    }
  }
  &__images {
    margin-bottom: 30px;
    @include l-more-than-mobile {
      display: flex;
      justify-content: space-between;
    }
    &-item {
      @include l-more-than-mobile {
        width: 230px;
      }
      @include l-mobile {
        margin-top: 15px;
        &:first-child {
          margin-top: 0;
        }
      }
      img {
        width: 100%;
        display: block;
      }
    }
  }
  &__btn {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, .1);
    color: $color-text;
    font-family: $font-m-plus;
    text-decoration: none;
    text-transform: uppercase;
    @include l-more-than-mobile {
      width: 240px;
      @include fontSize(14);
    }
    @include l-mobile {
      @include fontSize(16);
    }
  }
  //
  //interaction
  //
  &__btn {
    transition-property: background-color, color;
    transition-duration: .4s;
    transition-timing-function: ease;
    &.is-over {
      background-color: rgba(255, 255, 255, .5);
    }
  }
}
