@mixin fontSize($size: 14) {
  font-size: $size + px;
  font-size: calc($size / 16) * 1rem;
}
@mixin fontSizeMobile($size: 14) {
  @media all and (min-width: $break-point-mobile-p + 1) and (max-width: $break-point-tablet-p - 1px) {
    font-size: round($size / 375 * 414px);
  }
  @media all and (max-width: $break-point-mobile-p) {
    font-size: $size / 375 * 100vw;
  }
}
@mixin fontSizeAll($sizePc, $sizeTab, $sizeSp) {
  @include l-pc {
    @include fontSize($sizePc);
  }
  @include l-tablet {
    @include fontSize($sizeTab);
  }
  @include fontSizeMobile($sizeSp);
}
@mixin flexFontSize($vw) {
  @include l-pc-l {
    font-size: $break-point-pc-s / 100 * $vw;
  }
  @include l-pc-s {
    font-size: $vw * 1vw;
  }
  @include l-tablet {
    font-size: $vw * 1vw;
  }
  @include l-mobile {
    font-size: $break-point-tablet-p / 100 * $vw;
  }
}
@mixin clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}
@mixin iterateTransitionDelay($size, $step, $base) {
  @for $i from 1 through $size {
    &:nth-of-type(#{$i}) {
      transition-delay: (($i - 1) * $step + $base) * 1s;
    }
  }
}
@mixin iterateAnimationDelay($size, $step, $base) {
  @for $i from 1 through $size {
    &:nth-of-type(#{$i}) {
      animation-delay: (($i - 1) * $step + $base) * 1s;
    }
  }
}

//
// rwd break points
// pc-l | pc-s | tablet | mobile
//
// ○ | - | - | -
@mixin l-pc-l {
  @media all and (min-width: $break-point-pc-s + 1px) {
    @content;
  }
}
// - | ○ | - | -
@mixin l-pc-s {
  @media all and (min-width: $break-point-tablet-l + 1px) and (max-width: $break-point-pc-s) {
    @content;
  }
}
// - | - | ○ | -
@mixin l-tablet {
  @media all and (min-width: $break-point-tablet-p) and (max-width: $break-point-tablet-l) {
    @content;
  }
}
@mixin l-tablet-l {
  @media all and (min-width: $break-point-tablet-p) and (max-width: $break-point-tablet-l) and (orientation: landscape) {
    @content;
  }
}
@mixin l-tablet-p {
  @media all and (min-width: $break-point-tablet-p) and (max-width: $break-point-tablet-l) and (orientation: portrait) {
    @content;
  }
}
// - | - | - | ○
@mixin l-mobile {
  @media all and (max-width: $break-point-tablet-p - 1px) {
    @content;
  }
}
@mixin l-mobile-l {
  @media all and (max-width: $break-point-tablet-p - 1px) and (orientation: landscape) {
    @content;
  }
}
@mixin l-mobile-p {
  @media all and (max-width: $break-point-tablet-p - 1px) and (orientation: portrait) {
    @content;
  }
}
// ○ | ○ | - | -
@mixin l-pc {
  @media all and (min-width: $break-point-tablet-l + 1px) {
    @content;
  }
}
// - | - | ○ | ○
@mixin l-tablet-and-mobile {
  @media all and (max-width: $break-point-tablet-l) {
    @content;
  }
}
// ○ | ○ | ○ | -
@mixin l-more-than-mobile {
  @media all and (min-width: $break-point-tablet-p) {
    @content;
  }
}
// - | ○ | ○ | ○
@mixin l-less-than-pc-s {
  @media all and (max-width: $break-point-pc-s) {
    @content;
  }
}
