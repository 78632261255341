.u-dn {
  &-pc {
    @include l-pc {
      display: none;
    }
  }
  &-pc-l {
    @include l-pc-l {
      display: none;
    }
  }
  &-pc-s {
    @include l-pc-s {
      display: none;
    }
  }
  &-tablet {
    @include l-tablet {
      display: none;
    }
  }
  &-tablet-l {
    @include l-tablet-l {
      display: none;
    }
  }
  &-tablet-p {
    @include l-tablet-p {
      display: none;
    }
  }
  &-mobile {
    @include l-mobile {
      display: none;
    }
  }
  &-more-mobile {
    @include l-more-than-mobile {
      display: none;
    }
  }
}
