.p-faq-list {
  margin-bottom: 50px;
  &__item {
    margin-top: 30px;
    border-radius: 5px;
    background-color: $color-bg-dark;
    line-height: calc(51 / 32);
  }
  &__q, &__a {
    padding-top: 27px;
    padding-bottom: 24px;
    padding-left: 30px;
    padding-right: 30px;
    @include l-more-than-mobile {
      display: flex;
    }
    &-head {
      font-weight: 600;
      @include l-more-than-mobile {
        width: 95px;
      }
      @include l-mobile {
        margin-bottom: 8px;
      }
    }
    &-body {
      @include l-more-than-mobile {
        width: 645px;
      }
    }
  }
  &__q {
    &-body {
      font-weight: 600;
    }
  }
  &__a {
    color: $color-text-grey;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
  }
}
