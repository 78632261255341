body[data-content='chrome'] {
  [data-page-id='chromefaq']{
    .accordion {
      &-button {
        display: flex;
        width: 100%;
        background-color: transparent;
      }
      &-title {
        @extend %notosans-medium;
        color: color(text);
        font-size: 16px;
        letter-spacing: 0.03em;
      }
      &-icon {
        position: absolute;
        top: 16px;
        right: 15px;
        &::before,
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          width: 39px;
          height: 31px;
          background-size: 100%;
          background-repeat: no-repeat;
          content: '';
        }
        &::before {
          background-image: url("../img/chrome/faq/icon_arrow_white.png");
        }
        &::after {
          display: none;
          background-image: url("../img/chrome/faq/icon_arrow_blue.png");
        }
      }
      &-frame {
        height: 0;
        overflow: hidden;
        transition: height .25s ease-in-out;
      }
      &.is-active {
        .accordion-icon {
          &::before {
            display: none;
          }
          &::after {
            display: block;
          }
        }
      }
      &.contentsbox-inner {
        padding: 0;
        .accordion-button {
          padding: 20px 90px 20px 53px;
        }
        .accordion-content {
          padding: 0 90px 30px 53px;
        }
      }
    }
  }
}