.p-global-menu {
  position: fixed;
  top: 0; left: 0; bottom: 0; right: 0;
  z-index: 1000;
  &__bg {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba($color-bg, 0.95);
  }
  &__line {
    width: 1px;
    height: calc(100% - 42px);
    position: absolute;
    top: 42px; left: 40px;
    background-color: rgba(255, 255, 255, 0.3);
  }
  &__wrap {
    display: flex;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  &__items {
    display: flex;
    flex-direction: column;
    margin-left: 80px;
  }
  &__main-item {
    margin-bottom: 20px;
    font-family: $font-m-plus;
    @include fontSize(28);
    letter-spacing: -0.05em;
  }
  &__sub {
    display: flex;
    &-item {
      margin-left: 20px;
      @include fontSize(12);
      &:first-child {
        margin-left: 0;
      }
    }
  }
  //
  //interaction
  //
  pointer-events: none;
  &.is-opened {
    pointer-events: auto;
  }
  &__bg {
    opacity: 0;
    transition: opacity .6s $easeOutCirc;
  }
  &__line {
    transform-origin: top;
    transform: scaleY(0)
  }
  &__main-item {
    opacity: 0;
    transform: translate3d(0, -10px, 0);
  }
  &__sub {
    opacity: 0;
    transform: translate3d(0, -10px, 0);
  }
  &.is-opened &{
    &__bg {
      opacity: 1;
    }
    &__line {
      transition: transform .9s .4s $easeOutCirc;
      transform: scaleY(1);
    }
    &__main-item {
      transition-property: transform, opacity;
      transition-timing-function: ease;
      transition-duration: .7s;
      @include iterateTransitionDelay(4, 0.05, 0.3);
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
    &__sub {
      transition-property: transform, opacity;
      transition-timing-function: ease;
      transition-duration: .7s;
      transition-delay: .57s;
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  &.is-closed &{
    &__bg {
      transition: transform .5s $easeOutCirc;
    }
  }
}
