.p-aboutapp-content {
  margin-bottom: 50px;
  position: relative;
  z-index: 0;
  &__disc {
    margin-bottom: 45px;
    color: $color-text-grey;
    line-height: calc(51 / 32);
  }
  &__panel {
    @include l-more-than-mobile {
      margin-bottom: 44px;
    }
    @include l-mobile {
      margin-bottom: 22px;
    }
    &-item {
      overflow: hidden;
      border-radius: 10px;
      background-color: $color-bg-dark;
      margin-bottom: 20px;
      &:last-of-type {
        margin-bottom: 0;
      }
      @include l-more-than-mobile {
        display: flex;
      }
      @include l-mobile {
        margin-bottom: 30px;
      }
      &-thum {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        width: 400px;
        padding-top: 30px;
        flex-shrink: 0;
        background-color: $color-app-img-bg;

        img {
          display: block;
          width: 45%;
        }
        @include l-mobile {
          width: 100%;
          img {
            width: 53.7%;
          }
        }
      }
      &-text {
        padding-top: 33px;
        padding-bottom: 28px;
        padding-left: 30px;
        padding-right: 30px;
      }
      &-heading {
        position: relative;
        margin-top: 0;
        margin-bottom: 13px;
        padding-left: 40px;
        font-family: $font-m-plus;
        @include fontSize(16);
        line-height: calc(51 / 32);
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 50%; left: -10px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          transform: translateY(-50%);
        }
        &--defense {
          &::before {
            width: 45px;
            height: 43px;
            background-image: url(../img/mobile-app/aboutapp/icon_defense.png);
            @include l-mobile {
              top: -5px;
              transform: translateY(0%);
            }
          }
        }
        &--program {
          &::before {
            width: 48px;
            height: 35px;
            background-image: url(../img/mobile-app/aboutapp/icon_program.png);
          }
        }
        &--report {
          &::before {
            width: 47px;
            height: 44px;
            background-image: url(../img/mobile-app/aboutapp/icon_report.png);
          }
        }
        &--network {
          &::before {
            width: 45px;
            height: 45px;
            background-image: url(../img/mobile-app/aboutapp/icon_network.png);
          }
        }
      }
      &-disc {
        color: $color-text-grey;
        line-height: calc(51 / 32);
      }
    }
  }
  &__note {
    color: rgba($color-text-grey, 0.5);
    @include fontSize(12);
    line-height: calc(38 / 24);
  }
}
