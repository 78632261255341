.p-index-kv {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  @include l-more-than-mobile {
    background-image: url(../img/mobile-app/index/kv_bg.jpg);
    background-position: center;
  }
  @include l-mobile {
    position: relative;
    z-index: 0;
    padding-top: 105px;
    padding-left: 20px;
    padding-right: 20px;
    background-image: url(../img/mobile-app/index/kv_bg_sp.jpg);
    background-position: center;
  }
  &__wrap {
    margin-left: auto;
    margin-right: auto;
    @include l-more-than-mobile {
      width: 960px;
      height: 100%;
      display: flex;
      align-items: center;
    }
    @include l-mobile {
      position: relative;
      z-index: 20;
    }
  }
  &__in {
    @include l-more-than-mobile {
      width: 310px;
    }
    @include l-mobile {
      width: 330px;
      display: inline-block;
    }
  }
  &__heading {
    margin-top: 0;
    margin-bottom: 18px;
    font-family: $font-m-plus;
    @include fontSize(28);
    letter-spacing: -0.12em;
    line-height: calc( 72.8 / 56 );
  }
  &__disc {
    @include fontSize(14);
    line-height: calc(44 / 28);
    @include l-more-than-mobile {
      padding-right: 30px;
    }
    @include l-mobile {
      padding-right: 3em;
    }
  }
  &__aboutapp {
    @include fontSize(14);
    font-weight: 600;
    color: $color-light-blue;
    text-decoration: none;
  }
  &__google-play {
    display: block;
    width: 160px;
    margin-top: 19px;
    img {
      width: 100%;
      display: block;
    }
  }
}
