%notosans-light {
  font-weight: 300;
}

%notosans-regular {
  font-weight: 400;
}

%notosans-medium {
  font-weight: 500;
}

%notosans-bold {
  font-weight: 700;
}

%seira-medium {
  font-family: 'Saira', sans-serif;
  font-weight: 500;
}
