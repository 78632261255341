.p-template {
  padding-bottom: 80px;
  &__wrap {
    img {
      width: 100%;
      display: block;
      margin-top: 25px;
      margin-bottom: 25px;
    }
    a {
      color: $color-light-blue;
      text-decoration: none;
    }
    p {
      margin-top: 20px;
      margin-bottom: 20px;
      color: $color-text-grey;
      line-height: calc(51 / 32);
      &.histories {
        text-align: right;
        margin-top: 30px;
      }
    }
    h1, h2, h3, h4 {
      font-family: $font-m-plus;
      & + img, & + p, & + ul, & + ol, & + table {
        margin-top: 0;
      }
    }
    h1 {
      margin-top: 0;
      margin-bottom: 23px;
      padding-bottom: 15px;
      border-bottom: 4px solid #fff;
      font-family: $font-m-plus;
      font-size: 1.8rem;
      line-height: 1.3;
    }
    * {
      & + h1 {
        margin-top: 60px;
      }
    }
    h2 {
      @include fontSize(20);
      margin-top: 23px;
      margin-bottom: 26px;
      padding-bottom: 4px;
      border-bottom: 3px solid rgba(255, 255, 255, .7);
      line-height: 1.3;
    }
    h3 {
      position: relative;
      margin-top: 26px;
      margin-bottom: 20px;
      padding-left: 14px;
      @include fontSize(18);
      line-height: 1.3;
      &::before {
        display: block;
        content: '';
        width: 4px;
        height: 20px;
        position: absolute;
        top: 4px; left: 0;
        background-color: $color-light-blue;
      }
    }
    h4 {
      margin-top: 10px;
      margin-bottom: 5px;
      @include fontSize(16);
    }
    ol, ul {
      margin-top: 40px;
      margin-bottom: 40px;
      list-style-type: none;
      line-height: calc(51 / 32);
      li {
        position: relative;
        margin-top: 10px;
        padding-left: 26px;
        color: $color-text-grey;
        &:first-child {
          margin-top: 0;
        }
        &::before {
          position: absolute;
          left: 0;
        }
      }
    }
    ol {
      counter-reset: item;
      padding-left: 7px;
      li {
        &::before {
          counter-increment: item;
          content: counter(item);
          top: 0;
          color: $color-text;
          font-weight: 600;
          font-family: $font-m-plus;
        }
      }
    }
    ul {
      padding-left: 0;
      li {
        &::before {
          content: '';
          display: block;
          width: 14px;
          height: 14px;
          top: 5px;
          background-image: url(../img/mobile-app/common/ul_list.png);
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
      }
    }
    table {
      width: 100%;
      margin-top: 40px;
      margin-bottom: 40px;
      color: $color-text-grey;
      line-height: calc(51 / 32);
      tr {
        border-bottom: 1px solid rgba(255, 255, 255, .2);
      }
      th {
        text-align: left;
      }
      thead {
        th {
          padding-bottom: 11px;
          padding-right: 10px;
          @include fontSize(12);
          white-space: no-wrap;
          vertical-align: top;
        }
      }
      tbody {
        th, td {
          padding-top: 23px;
          padding-bottom: 23px;
          vertical-align: top;
        }
        th {
          min-width: 85px;
          padding-right: 20px;
          box-sizing: border-box;
        }
        td {
          word-break: break-all;
          padding-right: 10px;
          &:first-child {
            min-width: 85px;
            box-sizing: border-box;
            font-weight: 600;
          }
          &:nth-child(3) {
            max-width: calc((100% - 85px) / 2);
          }
          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }
  &__anchor {
    padding-top: 125px;
  }

  //
  //interaction
  //
  &__wrap {
    a {
      transition: opacity .3s ease;
      &:hover {
        opacity: 0.5;
      }
    }
  }
}
