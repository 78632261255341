.p-index-news {
  @include l-more-than-mobile {
    padding-top: 92px;
    padding-bottom: 100px;
  }
  @include l-mobile {
    padding-top: 46px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }
  &__wrap {
    @include l-more-than-mobile {
      width: 960px;
      margin-left: auto;
      margin-right: auto;
    }
    @include l-mobile {

    }
  }
  &__heading {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 5px;
    border-bottom: 4px solid rgba(255, 255, 255, 0.5);
    font-family: $font-m-plus;
    @include fontSize(20);
  }
  &__list {
    list-style-type: none;
    margin-top: 0;
    padding-left: 0;
    &-item {
      width: 100%;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      @include l-more-than-mobile {
        padding-top: 24px;
        padding-bottom: 23px;
      }
      @include l-mobile {
        padding-top: 20px;
        padding-bottom: 22px;
      }
      & > a {
        @include l-more-than-mobile {
          display: flex;
          width: 100%;
        }
        @include l-mobile {
          display: block;
        }
      }
      &-date {
        box-sizing: border-box;
        font-family: $font-m-plus;
        @include fontSize(14);
        letter-spacing: -0.05em;
        @include l-more-than-mobile {
          width: 105px;
          padding-right: 10px;
        }
        @include l-mobile {
          margin-bottom: 2px;
          opacity: 0.8;
        }
      }
      &-title {
        color: $color-text-grey;
        line-height: calc(51 / 32);
        @include l-more-than-mobile {
          width: calc(100% - 105px);
        }
      }
    }
  }
  //
  //interaction
  //
  &__list {
    &-item {
      &-title {
        transition: color .4s ease;
      }
      a.is-over &{
        &-title {
          color: $color-light-blue;
        }
      }
    }
  }
}
